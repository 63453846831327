import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";
import axios from 'axios';
import Loader from "../Loader"
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";
import { InlineWidget } from "react-calendly";

const Calander = () => {
   
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');


    const postData = async () => {
    }

    const resetData = async () => {
        
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">add_photo_alternate</span>
                            <h1>Calander</h1>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="add_content">Select a Date</label>
                                    <div className="" style={{ "position": "relative" }}>
                                    <InlineWidget url="https://calendly.com/your_scheduling_page" />

                                        
                                    </div>
                                </div>

                                

                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
            {
                loading ? <Loader /> : ""
            }
            <Toaster message={message} mode={mode} />
        </div>

    )

}
export default Calander;