import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AuthGuard = ({component}) => {
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();
    }, [component]);

    const checkToken = async () => {
        // try {
        //     let user = await AsyncStorage.getItem('profile');
        //   if (!user) {
        //     navigate(`/login`);
        //   }
        //   setStatus(true);
        //   return;
        // } catch (error) {
        //   navigate(`/login`);
        // }
        setStatus(true);
      }

  return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AuthGuard;