import { React, useState } from 'react';
import axios from 'axios';
import Header from "../Header/header";
import Loader from "../Loader"
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import File_Uploader from '../FileUpload/fileupload';
import Toaster from "../Toaster/Toaster";
import AsyncStorage from '@react-native-async-storage/async-storage';

const Assessment = () => {
    const [text, setText] = useState('');
    const [objective, setObjetive] = useState('');
    const [bloom, setBloom] = useState("Remembring");
    const [mcms, setMcms] = useState(null);
    const [mcmsList, setMmsList] = useState(null);
    const [rationale, setRationale] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [matchQuestion, setMatchQue] = useState('Multiple Choice Single Select');
    const [matchTable, setMatchTable] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');
    const [loObject, setLoObject] = useState('');

    const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const questionType = [
        { id: "Multiple Choice Single Select" }, { id: "Multiple Choice Multiple Select" }, { id: "Matching" }
    ]
    const bloomsType = [
        { id: "Remembring" }, { id: "Understanding" }, { id: "Applying" }, { id: "Analyzing" }, { id: "Evaluating" }, { id: "Creating" }
    ]
    const [selectedValue, setSelectedValue] = useState('Multiple Choice Single Select');
    const formData = {
        "text": text,
        "learning_objective": loObject,
        "bloom_taxonomy": bloom
    }

    const resetData = async () => {
        setText('');
        setLoading(false);
        setObjetive('');
        setBloom("Remembring");
        setMcms(null);
        setMmsList(null);
        setRationale(null);
        setAnswer(null);
        setSelectedValue('Multiple Choice Single Select');
        setMatchTable(null);
    }

    window.onload = async () => {
        const data2 = await AsyncStorage.getItem("text");
        const lo = await AsyncStorage.getItem("learning_gole");
        setLoObject(lo);
        setText(data2??'');
        AsyncStorage.removeItem('text');
        AsyncStorage.removeItem('learning_gole');
    }

    const uploadedTextData = (text1) => {
        setText(text1);
    }

    const callApi = async (url) => {
        let data = [];
        try {
            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json, text/plain, /'
                },
            }).then((response) => {
                data = response.data.data;
            });
        } catch (error) {
            console.log('error', error);
        }

        return data;
    }
    const postData = async () => {
        let validForm = true;
        if (formData.text == '') {
            validForm = false;
            setMode("error");
            setMessage("Please type or paste or select a file for processing.");
        }
        if (formData.learning_objective == '') {
            validForm = false;
            setMode("error");
            setMessage("Please enter Learning objective.");
        }
        let hasValue = false;
        if (validForm) {
            try {

                setMatchTable(null);
                setMmsList(null);
                setLoading(true);
                if (selectedValue.includes('Multiple Choice Single Select')) {
                    const response = await callApi(process.env.REACT_APP_ASSESMENT_FIRST_URL);
                    const updatedData = response.assessment_MCSS;
                    setMcms(updatedData.question);
                    setMmsList(updatedData.options.map((item, index) => (
                        <li key={index}>{alpha[index]}. {item.option}</li>
                    )));
                    setRationale(updatedData.options.map((item, index) => (
                        <li key={index}><strong>{alpha[index]}. {item.option}:</strong> {item.rationale}</li>
                    )));
                    setAnswer(updatedData.options.map((item, index) => (
                        <p key={index} className="right_choice m-3">{item.isCorrect ? alpha[index] : ''}{item.isCorrect ? '.' : ''} {item.isCorrect ? item.option : ''}</p>
                    )));
                    hasValue = updatedData.question && updatedData.question.length > 0;
                }
                if (selectedValue.includes("Multiple Choice Multiple Select")) {
                    const response = await callApi(process.env.REACT_APP_ASSESMENT_SECOND_URL);
                    const updatedData = response.assessment_MCMS;
                    setMcms(updatedData.question);
                    setMmsList(updatedData.options.map((item, index) => (
                        <li key={index}>{alpha[index]}. {item.option}</li>
                    )));
                    setRationale(updatedData.options.map((item, index) => (
                        <li key={index}><strong>{alpha[index]}. {item.option}:</strong> {item.rationale}</li>
                    )));
                    setAnswer(updatedData.options.map((item, index) => (
                        <p key={index} className="right_choice m-3">{item.isCorrect ? alpha[index] : ''}{item.isCorrect ? '.' : ''} {item.isCorrect ? item.option : ''}</p>
                    )));
                    hasValue = updatedData.question && updatedData.question.length > 0;
                }
                if (selectedValue.includes('Matching')) {
                    const response = await callApi(process.env.REACT_APP_ASSESMENT_THIRD_URL);
                    const updatedData = response.assessment_matching;
                    setMatchQue(updatedData.question)
                    setMatchTable(updatedData.answers.map((item, index) => (
                        <tr key={index}>
                            <td>{item.term}</td>
                            <td>{item.definition}</td>
                        </tr>
                    )));
                    hasValue = updatedData.question && updatedData.question.length > 0;
                }

            } catch (error) {
                console.log("error", error);
                setMode("error");
                setMessage("Error while process the request.")
            }
            setLoading(false);
            if (hasValue) {
                setMode("success");
                setMessage("Please scroll down to see the generated result.");
            } else {
                setMode("error");
                setMessage(process.env.REACT_APP_NO_DATA);
            }
        }
    }
    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center content-name">
                            {/* <span className="material-icons">quiz</span> */}
                            <span className="material-symbols-sharp">text_fields</span>
                            <h1>Assessment</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Create Assessment on any topic with ease.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="add_content">Add Content</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT}className="form-control" value={text} placeholder="Paste your Text here" id="add_content" style={{ "minHeight": "170px" }} onChange={(e) => setText(e.target.value)}></textarea>
                                        <div className="typedCount">You've typed {text.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                        <div className="lines"><span>OR</span></div>
                                        <File_Uploader onFileUpload={uploadedTextData} />
                                        <label htmlFor="learning_object">Learning objective</label>
                                        <input type='text' className="form-control" value={loObject} placeholder="Add your learning objective here..." id="learning_object" onChange={(e) => setLoObject(e.target.value)} />
                                    </div>
                                </div>
                                <div className="inputBox  content-curation_drop">
                                    <label htmlFor="question_drop">Bloom's Taxonomy</label>
                                    <div className=" multipleSelection">
                                        <select className="form-select mb-3" aria-label="" id="question_drop" value={bloom} onChange={(e) => setBloom(e.target.value)} >
                                            {bloomsType.map((option) => {
                                                return <option key={option.id} value={option.id}>{option.id}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="inputBox  content-curation_drop">
                                    <label htmlFor="qst_type">Question Type</label>
                                    <div className=" multipleSelection">
                                        <select className="form-select mb-3" aria-label="" id="question_drop" value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} >
                                            {questionType.map((option) => {
                                                return <option key={option.id} value={option.id}>{option.id}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Generate</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </main>{
                matchTable || mcmsList ?
                    <div className="contents-part mt-3">
                        <div className="container">
                            {
                                mcmsList ? <div className="row">
                                    <div className="col-sm-12">
                                        <div className="contextual-alt-area summarization_area" style={{ "minHeight": "280px" }} >
                                            <div className="inputBox">
                                                <p>Generated Result - {selectedValue}</p>
                                                <div className="wrapper-box content-curation-wrapper assessment_content" >
                                                    <div className="accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    Question - {mcms}
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className="accordion-body-inner-box">
                                                                        <h3>Options</h3>
                                                                        <div>
                                                                            <ul style={{ "listStyleType": "none" }}>
                                                                                {mcmsList}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-body-inner-box mt-3">
                                                                        <h3>Answer</h3>
                                                                        {answer}
                                                                        <h3 className='mt-3'>Rationale :</h3>

                                                                        <ul style={{ "listStyleType": "none" }}>
                                                                            {rationale}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : ""

                            }
                            {
                                matchTable ?
                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <div className="contextual-alt-area summarization_area" style={{ "min-height": "280px;" }} >
                                                <div className="inputBox">
                                                    <div className="wrapper-box content-curation-wrapper key_point" >
                                                        <table className="table table-bordered" aria-labelledby="answer">
                                                            <caption>{matchQuestion}</caption>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Term</th>
                                                                    <th scope="col">Definition</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {matchTable}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                            <div className="inputBox d-flex justify-content-end BtnBox">
                                <button type="reset" className="btn btn-primary btn_reset">Reset</button>
                                <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Regenerate</button>
                            </div>
                            <br />
                        </div>
                    </div>
                    : ""
            }

            {loading ? <Loader /> : ""}
            <Toaster message={message} mode={mode} />
        </div>
    )
}

export default Assessment;