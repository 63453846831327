import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import AsyncStorage from '@react-native-async-storage/async-storage';
import './login.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../../images/LearningMate-logo.svg'
import { useNavigate } from 'react-router-dom';
import { tokens } from "../theme";
import Toaster from "../Toaster/Toaster";
import Loader from "../Loader";
import axios from "axios";


const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setpassword] = useState("");
    const [enableButton, setEnableButton] = React.useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');
    const [loading, setLoading] = useState(false);

    const handleEmail = (event) => {
        setEmail(event.target.value);
        checkTheInput();
    };
    const handlePassword = (event) => {
        setpassword(event.target.value);
        checkTheInput();
    };

    const checkTheInput = () => {
        setEnableButton(email !== '' && password !== '');
    };

    const handleSingIn = async (event) => {
        const payload = {
            email: email,
            password: password,
        };
        setLoading(true);
        event.preventDefault();
        await axios.post(process.env.REACT_APP_AI_WORKBENCH_URL + "/lmtutor/auth/signin", payload, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, /'
            },
        }).then(async (response) => {
            debugger
            if (response.status === 422) {
                setMode('Error');
                setMessage("Please provide required inputs!");
            } else {
                if (response.data["status_code"] === 200) {
                    await AsyncStorage.setItem('profile', response.data);
                    navigate("/");
                } else {
                    setMode('Error');
                    setMessage(response.data.message);
                }
            }
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            console.log("errors", errors.response.data.detail)
            const errorText = errors.response.data.detail.map((error) => { return JSON.stringify(error.loc[error.loc.length - 1] + " : " + error.msg) });
            setMode('Error');
            setMessage(JSON.stringify(errorText.join("\n")));
        });
    };


    return (
        <div className="full-area">
            <header className="top-part">
                <div>
                    <img src={logo} alt="LearningMate" />
                    <br /><span style={{ 'margin-left': '30px' }}>Welcome to Tutordemo, Please Sign up to use the tool
                        <br /><span style={{ 'margin-left': '30px', 'color': colors.greenAccent[400] }}>A Tool That enables you to try Multiple LLMs</span>
                    </span>

                </div>
                <div>
                    {/* <a href="/book-a-demo" className="btn_contactUs">Book a Demo</a> */}
                    <a href={process.env.REACT_APP_USER_GUIDE_URL} target="_blank" className="btn_contactUs">User Guide</a>
                    <a href={process.env.REACT_APP_AI_WORKBENCH_URL} target="_blank" className="btn_contactUs">AI WORKBENCH</a>
                    <a href={process.env.REACT_APP_SUGGESTIONS} target="_blank" className="btn_contactUs">SUGGESTIONS</a>
                </div>

            </header>
            <div className="signin-container">
                <h2 className="signin-heading">Sign In</h2>
                <form onSubmit={handleSingIn}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="username">
                            Username
                        </label>
                        <input
                            className="form-input"
                            type="text"
                            id="username"
                            name="username"
                            onChange={handleEmail}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="form-input"
                            type="password"
                            id="password"
                            name="password"
                            onChange={handlePassword}
                        />
                    </div>
                    <button disabled={!enableButton} className="signin-button" type="submit">
                        Sign In
                    </button>
                </form>
            </div>
            <Toaster message={message} mode={mode} />
            {loading ? <Loader /> : ""}
        </div>
    )
}
export default Login;