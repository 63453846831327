import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Login/login.css'
import Header from "../Header/header";
import axios from 'axios';
import Loader from "../Loader"
import Back from "../Back/back";
import 'react-toastify/dist/ReactToastify.css';
import Toaster from "../Toaster/Toaster";
import { useNavigate } from 'react-router-dom';

const BookDemo = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [organization, setOrganization] = useState('');
    const [role, setRole] = useState('');
    const [useCases, setUsecases] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [targetAudience, setTargetAudience] = useState('');

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [mode, setMode] = useState('success');


    const postData = async () => {
        navigate('/calander')
    }

    const resetData = async () => {
        setName('');
        setLoading(false);
        setOrganization('');
        setRole('');
        setUsecases('');
        setContactEmail('');
        setTargetAudience('');
    }

    return (
        <div>
            <Header />
            <main className="contents-part">
                <div className="container">
                    <Back />
                    <div className="row">
                        <div className="col-sm-12 mt-3 d-flex justify-content-start align-items-center">
                            <span className="material-symbols-sharp">add_photo_alternate</span>
                            <h1>Book a Demo</h1>
                        </div>
                        <div className="col-sm-12 mt-3 d-flex justify-content-start content-small">
                            <span className="material-symbols-rounded">info</span>
                            <p>Complete this form to schedule a demo. Our representative will be in touch with you soon.</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <div className="wrapper-ai-case-content summarization_area" >
                                <div className="inputBox">
                                    <label htmlFor="add_content">Name</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <input type='text' className="form-control" value={name} placeholder="Enter your name" id="txtName" onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>

                                <div className="inputBox">
                                    <label htmlFor="add_content">Organization</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <input type='text' className="form-control" value={organization} placeholder="Enter your organization" id="txtOrganization" onChange={(e) => setOrganization(e.target.value)} />
                                    </div>
                                </div>

                                <div className="inputBox">
                                    <label htmlFor="add_content">Role</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <input type='text' className="form-control" value={role} placeholder="Enter your role" id="learning_object" onChange={(e) => setRole(e.target.value)} />
                                    </div>
                                </div>

                                <div className="inputBox">
                                    <label htmlFor="add_content">Use cases</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <textarea maxLength={process.env.REACT_APP_TEXT_LIMIT} className="form-control" value={useCases} placeholder="Enter Use cases" id="add_content" style={{ "minHeight": "170px" }} onChange={(e) => setUsecases(e.target.value)}></textarea>
                                        <div className="typedCount">You've typed {useCases.length}/{process.env.REACT_APP_TEXT_LIMIT}.</div>
                                    </div>
                                </div>

                                <div className="inputBox">
                                    <label htmlFor="add_content">Contact Email</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <input type='text' className="form-control" value={contactEmail} placeholder="Enter your Email" id="txtContactEmail" onChange={(e) => setContactEmail(e.target.value)} />
                                    </div>
                                </div>

                                <div className="inputBox">
                                    <label htmlFor="add_content">Target Audience</label>
                                    <div className="" style={{ "position": "relative" }}>
                                        <input type='text' className="form-control" value={targetAudience} placeholder="Enter target audience" id="learning_object" onChange={(e) => setTargetAudience(e.target.value)} />
                                    </div>
                                </div>

                                

                                <div className="inputBox d-flex justify-content-end BtnBox">
                                    <button type="reset" className="btn btn-primary btn_reset" onClick={resetData}>Reset</button>
                                    <button type="button" className="btn btn-primary btn_generate" onClick={postData}>Proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
            {
                loading ? <Loader /> : ""
            }
            <Toaster message={message} mode={mode} />
        </div>

    )

}
export default BookDemo;